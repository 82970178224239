import { useState } from "react";
import Collapsible from "react-collapsible";
import "./Filters.css";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Categories } from "../Map";

const Filters = ({
  locationHooks,
  categories,
  tags,
  selectCategories,
  selectedCategories,
}) => {
  const [open, setOpen] = useState(true);
  const triggerSiblingExample = () => (
    <span className="open-close-icon" onClick={() => setOpen(!open)}>
      <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
    </span>
  );

  return (
    <Collapsible
      trigger="Filtri"
      containerElementProps={{ id: "filters" }}
      triggerSibling={triggerSiblingExample}
      handleTriggerClick={() => setOpen(!open)}
      open={open}
    >
    <p className="categories">Categorie</p>
      {Categories.map((category, i) => {
        return (
          <div className="certificates-line" key={i}>
            <p className={`certificates-name ${selectedCategories.includes(category) ? 'certificates-name--active' : ''}`}
              onClick={() => selectCategories(category)}
            >
              {category}
            </p>
          </div>
        );
      })}
    </Collapsible>
  );
};

export default Filters;

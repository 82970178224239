import './CollapsibleNavbar.css';
// bootstrap component
import Nav from 'react-bootstrap/Nav';
import Collapse from 'react-bootstrap/Collapse';
import Accordion from 'react-bootstrap/Accordion';

const CollapsibleNavbar = ({open}) => {
  return (
    <Collapse in={open}>
      <div id='vertical-menu'>
        <Accordion className='menu-accordion'>
          <Nav.Link className='accordion-main-link' href='https://www.poolpharma.it/integrazione-evoluta/'>
            Integrazione Evoluta
          </Nav.Link>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>La Soluzione per Te</Accordion.Header>
            <Accordion.Body>
              <Nav className='accordion-nav'>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/vitalita-energia-efficienza/'>
                  - VITALITÀ + Energia + Efficienza
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/snellimento/'>
                  - SNELLIMENTO E COLESTEROLO + In Forma + Controllo
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/intestino/'>
                  - INTESTINO + Equilibrio + Regolarità
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/difesa/'>
                  - DIFESA + Protezione
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/organismo-salute-cura/'>
                  - ORGANISMO + Salute + Cura
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/vie-urinarie/'>
                  - VIE URINARIE + Funzionalità + Sollievo
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/sonno/'>
                  - SONNO E TONO DELL’UMORE + Tranquillità + Serenità
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/skincare/'>
                  - SKINCARE + Bellezza + Benessere
                </Nav.Link>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Essere Pool Pharma</Accordion.Header>
            <Accordion.Body>
              <Nav className='accordion-nav'>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/i-nostri-valori/'>
                  - I Nostri Valori
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/la-nostra-storia/'>
                  - La Nostra Storia
                </Nav.Link>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Approfondimenti e News</Accordion.Header>
            <Accordion.Body>
              <Nav className='accordion-nav'>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/consigli-di-benessere/'>
                  - Consigli di benessere
                </Nav.Link>
                <Nav.Link className='accordion-nav-item' href='https://www.poolpharma.it/le-stagioni-e-la-tua-salute/'>
                  - La tua salute, il tempo e le stagioni
                </Nav.Link>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
          <Nav.Link className='accordion-main-link border-bottom' href='https://probioticamente.it'>
            probioticamente.it
          </Nav.Link>
          <Nav.Link className='accordion-main-link border-bottom' href='https://www.poolpharma.it/store/'>
            STORE
          </Nav.Link>
        </Accordion>
      </div>
    </Collapse>
  );
};

export default CollapsibleNavbar;

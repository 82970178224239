import './Header.css'
import axios from 'axios';
import { useState } from "react";
// components
import HeaderNavbar from './HeaderNavbar';
import CollapsibleNavbar from './CollapsibleNavbar';
import SearchBar from './SearchBar';
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const poolpharmaSearch = async (event) => {
    event.stopPropagation();
    if (event.target.value.length > 1) {
      try {
          let formData = new FormData();
          formData.append('search', event.target.value);
          formData.append('per_page', 30);
          formData.append('action', 'live_search_retrieve_posts');
          formData.append('show_feat_img', 1);
          formData.append('display_post_type', 1);
          const response = await axios({
              method: 'post',
              url: 'https://www.poolpharma.it/wp-admin/admin-ajax.php',
              data: formData
            });
            setOptions(response.data);
      } catch (err) {
        console.log(err);
      }
    }
}

  return (
    <header className="header">
      <div className="header-container">
        <div className="first-row">
          <div className="first-row-container">
            <div className="logo-container">
              <a className="logo-anchor" href="https://www.poolpharma.it">
                <img src="assets/poolpharma-logo.png" className="logo-company"></img>
              </a>
            </div>
            <div className="search-container is-hidden-mobile">
              <SearchBar options={options} onInputChange={poolpharmaSearch} />
            </div>
            <div className='is-hidden-tablet'>
            <button 
              type="button" 
              className="bars-btn" 
              onClick={() => setOpen(!open)}
              aria-controls="vertical-menu"
              aria-expanded={open}>
                <FontAwesomeIcon icon={faBars} color="black" size="lg" />
              </button>
            </div>
          </div>
        </div>
        <div className="divider is-hidden-mobile" />
        <div className="navbar-container is-hidden-mobile">
          <HeaderNavbar />
        </div>
        <CollapsibleNavbar open={open} />
      </div>
    </header>
  );
};

export default Header;

import './HeaderNavbar.css';
// bootstrap component
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

const HeaderNavbar = () => {
  return (
    <Navbar className='justify-content-center'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='w-100'>
            <Nav.Link href='https://www.poolpharma.it/integrazione-evoluta/'>
                Integrazione Evoluta
            </Nav.Link>
            <NavDropdown title='La Soluzione per Te' id='integration'>
              <NavDropdown.Item href='https://www.poolpharma.it/vitalita-energia-efficienza/'>
                VITALITÀ + Energia + Efficienza
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/snellimento/'>
                SNELLIMENTO E COLESTEROLO + In Forma + Controllo
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/intestino/'>
                INTESTINO + Equilibrio + Regolarità
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/difesa/'>
                  DIFESA + Protezione
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/organismo-salute-cura/'>
                ORGANISMO + Salute + Cura
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/vie-urinarie/'>
                VIE URINARIE + Funzionalità + Sollievo
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/sonno/'>
                SONNO E TONO DELL’UMORE + Tranquillità + Serenità
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/skincare/'>
                SKINCARE + Bellezza + Benessere
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Essere Pool Pharma' id='history'>
              <NavDropdown.Item href='https://www.poolpharma.it/i-nostri-valori/'>
                I Nostri Valori
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/la-nostra-storia/'>
                La Nostra Storia
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Approfondimenti e News' id='news'>
              <NavDropdown.Item href='https://www.poolpharma.it/consigli-di-benessere/'>
                Consigli di benessere
              </NavDropdown.Item>
              <NavDropdown.Item href='https://www.poolpharma.it/le-stagioni-e-la-tua-salute/'>
                La tua salute, il tempo e le stagioni
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href='https://probioticamente.it'>
                probioticamente.it
            </Nav.Link>
            <Nav.Link href='https://www.poolpharma.it/store/' id='storeLink'>
              <FontAwesomeIcon icon={faShoppingBag} className='shopping-icon' />
              STORE
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;

import { useEffect, useState } from 'react';
import './StoresList.css';
import conf from '../config.json';
import { getCategories } from '../Map';
import StorePreview from '../components/StorePreview';

export default function StoresList({
  location,
  mapView,
  dataSource,
  selectedCategories,
  setSelectedStore,
}) {
  const [stores, setStores] = useState([]);
  const [results, setResults] = useState(true);
  useEffect(() => {
    search(location, selectedCategories);
  }, [location, selectedCategories]);

  const selectStore = (store) => {
    mapView.set('selectedStore', store);
    setSelectedStore(store);
  };

  const categoryFilter = (categories) => {
    let q = window.woosmap.query;
    const selectedCategoriesQuery = categories.reduce((acc, category) => {
      acc.push(q.F('type', category));
      return acc;
    }, []);
    if (selectedCategories.length <= 0) {
      return getCategories();
    }
    return selectedCategoriesQuery;
  };

  const search = (location, selectedCategories) => {
    if (location) {
      //Get Categories Filter
      const selectedCategoriesQuery = categoryFilter(selectedCategories);
      let q = window.woosmap.query;
      let query;
      
      if (selectedCategoriesQuery.length ) {
        query = q.or([...selectedCategoriesQuery]);
      } else if (selectedCategoriesQuery.length <= 0) {
        query = q.or();
      }
      
      const searchParams = new window.woosmap.search.SearchParameters({
        lat: location.lat,
        lng: location.lng,
        page: 1,
        storesByPage: conf.storesByPage,
        query: selectedCategoriesQuery.length ? query : '',
      });

      dataSource.searchStoresByParameters(searchParams, (nearbyStores) => {
        setStores(nearbyStores.features);
        if (nearbyStores.features && nearbyStores.features.length <= 0) {
          setResults(false);
        }
      });
    } else {
      setStores([]);
    }
  };

  return stores.length > 0 ? (
    <section className='storesList'>
      {stores.map((store) => (
        <div
          className='columns container is-vcentered is-centered'
          key={store.properties.store_id}
          onClick={() => selectStore(store)}
        >
          <StorePreview
            name={store.properties.name}
            address={store.properties.address}
            distance={store.properties.distance}
          />
        </div>
      ))}
    </section>
  ) : (
    stores.length <= 0 && !results && <NoResult />
  );
}

const NoResult = () => {
  return (
    <section className='storesList'>
      <div className='columns container is-vcentered is-centered'>
        <p> Nessun risultato per questa ricerca</p>
      </div>
    </section>
  );
};

import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="columns" style={{marginBottom: "0"}}>
          <div className="footer-copyright column is-6">
            <div className="footer-copyright--top">
              Copyright 2022 Pool Pharma - All Rights Reserved | Credits by <a className="footer-copyright--link" href="https://www.wellcare.it">Wellcare</a>
            </div>
            <div className="footer-copyright--bottom">
              <div>POOL PHARMA S.r.l.</div>
              <div>Via Basilicata, 9 - fraz. SESTO ULTERIANO 20098 – S. GIULIANO MILANESE (Mi)</div>
              <div>Tel. 02/98281522 – Fax 02/9881132 – Cod.Fiscale/P.IVA 09001230151</div>
            </div>
          </div>
          <div className="footer-socials column is-6">
            <a className="footer-socials--icons" href="https://www.facebook.com/poolpharma">
              <FontAwesomeIcon icon={faFacebookF} color="white" />
            </a>
            <a className="footer-socials--icons" href="https://www.youtube.com/channel/UCKF44u1Q9I2_21WlTDsr38g">
              <FontAwesomeIcon icon={faYoutube} color="white" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import './Map.css';
import React, { useRef, useEffect, useState } from 'react';
// utils
import conf from './config.json';
import useScript from './hooks/useScript';
// components
import Sidebar from './components/Sidebar';

export const Categories = process.env.REACT_APP_ACTIVEFILTERS ? 
  process.env.REACT_APP_ACTIVEFILTERS.toLowerCase().split(',') : 
  [];

export const getCategories = (initial = []) => {
  let q = window.woosmap.query;
  return Categories.reduce((acc, category) => {
    acc.push(q.F('type', category));
    return acc;
  }, initial);
};

const Map = () => {
  const mapContainerRef = useRef(null);
  const [mapRef, setMapRef] = useState(null);
  const [mapView, setMapView] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const woosmapLoaded = useScript(conf.woosmapLoaderUrl);
  const [category, setCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  useEffect(() => {
    if (woosmapLoaded) {
      initMap();
    }
  }, [woosmapLoaded]);

  const initMap = () => {
    conf.woosmapLoadOptions.callback = () => {
      const loader = new window.woosmap.MapsLoader(conf.googleLoadOptions);
      loader.load(() => {
        const map = new window.google.maps.Map(mapContainerRef.current, conf.googleMapsOptions);
        setMapRef(map);
        const dataSource = new window.woosmap.DataSource();
        const mapView = new window.woosmap.TiledView(map, conf.woosmapStyleOptions);

        const queries = getCategories();
        let q = window.woosmap.query;
        let query = q.or([...queries]);

        const initalSearchParams = new window.woosmap.search.SearchParameters({query});
        mapView.setSearchParameters(initalSearchParams);

        const searchParams = new woosmap.search.SearchParameters({
          lat: conf.googleMapsOptions.center.lat,
          lng: conf.googleMapsOptions.center.lng,
          // page: 1,
          // storesByPage: conf.storesByPage,
          query,
        });

        mapView.addListener('click', (store) => {
          mapView.set('selectedStore', store);
          new window.woosmap.maps.utils.centerAndZoom(
            map,
            { lat: store.geometry.coordinates[1], lng: store.geometry.coordinates[0] },
            9
          );
          setSelectedStore(store);
        });

        dataSource.searchStoresByParameters(searchParams, (stores) => {
          const features = stores.features;
          let categories = [],
            tags = [];
          features.forEach((feature) => {
            if (feature.properties.types[0] !== '') categories.push(feature.properties.types[0]);
            if (feature.properties.tags.length > 0) {
              feature.properties.tags.map((tag) => {
                tags.push(tag);
              });
            }
          });
          categories = Array.from(new Set(categories));
          tags = Array.from(new Set(tags));
          setCategory(categories);
          setTags(tags);
          setLoaded(true);
        });

        setMapView(mapView);
        setDataSource(dataSource);
      });
    };
    window.WoosmapLoader.load(conf.woosmapLoadOptions);
  };

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 80px)' }}>
      <div className='mapContainer' ref={mapContainerRef} />
      {loaded && (
        <Sidebar
          mapView={mapView}
          dataSource={dataSource}
          categories={category}
          tags={tags}
          map={mapRef}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
      )}
    </div>
  );
};

export default Map;

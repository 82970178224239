import React from "react";
import Map from "./Map";
import "./App.css";
import Header from "./components/Header";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/map">
          <Map />
        </Route>
        <Route path="/">
          <Redirect to="/map" />
        </Route>
      </Switch>
     <Footer />
    </Router>
  );
}

export default App;

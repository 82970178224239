import React, { useEffect } from 'react';
import conf from '../config.json';
import axios from 'axios';
// style
import './Autocomplete.css';
import './Aroundme.css';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';

const AroundMe = ({
  setLocationHooks,
  map,
  radius,
  marker,
  geoLocate,
  setRadius,
  setMarker,
  setGeoLocate,
  setKeyword,
  keyword,
}) => {
  useEffect(() => {
    if (!geoLocate && marker && radius) {
      marker.setMap(null);
      radius.setMap(null);
      setMarker(null);
      setRadius(null);
    }
  }, [geoLocate]);

  const getPosition = async (event) => {
    if (!geoLocate) {
      try {
        const { data } = await axios.get(
          `https://api.woosmap.com/geolocation/position?key=${conf.woosmapLoadOptions.publicKey}`
        );
        if (data.latitude) {
          const position = { lat: data.latitude, lng: data.longitude };
          var populationOptions = {
            strokeColor: '#34b3e5',
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: '#34b3e5',
            fillOpacity: 0.35,
            map: map,
            center: { lat: 45.4642, lng: 9.19 },
            radius: data.accuracy,
          };
          // Add the circle for this city to the map.
          const radius = new window.google.maps.Circle(populationOptions);

          const svgMarker = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: '#34b3e5',
            strokeColor: '#34b3e5',
            fillOpacity: 1,
            strokeWeight: 1,
            rotation: 0,
            scale: 7,
            // anchor: new google.maps.Point(15, 30),
          };
          const marker = new google.maps.Marker({
            position: { ...position },
            icon: svgMarker,
            map: map,
          });

          new window.woosmap.maps.utils.centerAndZoom(map, { ...position }, 14);
          setGeoLocate(true);
          setMarker(marker);
          setRadius(radius);
          setLocationHooks({ ...position });
          if (keyword !== '') {
            setKeyword('');
          }
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
    marker.setMap(null);
    radius.setMap(null);
    setMarker(null);
    setRadius(null);
    setGeoLocate(false);
  };

  return (
    <button 
      type='button'
      className={`around-me-button ${geoLocate ? 'around-me-button--active' : ''}`}
      onClick={(event) => getPosition(event, geoLocate, setGeoLocate, map)}
    >
      <span className='around-me-label'>Intorno a te</span>
      <FontAwesomeIcon icon={faCrosshairs} color={geoLocate ? 'red' : ''} size="1x" />
    </button>
  );
};

export default AroundMe;

import React, { useRef, useEffect } from 'react';
import './SearchBar.css';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = (props) => {
  const { options, onInputChange } = props;
  const ulRef = useRef();
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener('click', (event) => {
      event.stopPropagation();
      ulRef.current.style.display = 'flex';
      onInputChange(event);
    });
    document.addEventListener('click', (event) => {
      if (ulRef.current !== null) ulRef.current.style.display = 'none';
    });
  }, []);
  
  return (
    <div className='input-group'>
      <input
        id='search-bar'
        type='text'
        className='form-control'
        ref={inputRef}
        onChange={onInputChange}
        placeholder='Cerca...'
      />
      <button type='button' className='btn search-button'>
        <FontAwesomeIcon icon={faSearch} color='white' size='sm' />
      </button>
      <ul id='results' className='list-group' ref={ulRef}>
        {options.map((option) => {
          return (
             <a className='list-group-item list-group-item-action' key={option.id} href={option.post_url}>
                <div className='image-container'>
                    <img className='image-container-img' src={option.image_url ? option.image_url : ''} ></img>
                </div>
                <div className='description-container'>
                    <div className='description-container-title'>{option.title}</div>
                    <div className='description-container-type'>{option.type}</div>
                </div>
            </a>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchBar;
